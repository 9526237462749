/**
 * Plays well with historic artifacts
 */
function loadScriptIE(src) {
  const script = document.createElement('script');
  script.type = 'text/javascript';

  // IE
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
      }
    };
  }
  script.async - true;
  script.defer - true;
  script.src = src;
  document.body.appendChild(script);
}

/**
 * Loads a JavaScript file and returns a Promise for when it is loaded
 */
const loadScript = (src) => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.onload = resolve;
  script.onerror = reject;
  script.src = src;
  document.body.append(script);
});

// holds major version number for IE, or NaN if UA is not IE.
// Support: IE 9-11 only
/**
 * documentMode is an IE-only property
 * http://msdn.microsoft.com/en-us/library/ie/cc196988(v=vs.85).aspx
*/
const msie = window.document.documentMode;
// const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// if (msie <= 11 || safari) {
if (msie <= 11) {
  loadScriptIE('./assets/scripts/main.js');
  loadScriptIE('./assets/scripts/audioPlayerIE.js');
} else {
  loadScript('./assets/scripts/main.js')
    .then(() => loadScript('./assets/scripts/audioPlayer.js'))
    .catch(() => console.error('Something went wrong.'));
}
